/// ToastService class, manages the toast notifications
// mainly used for storing toast message jsons in localStorage
// and load them in newly rendered pages in App.vue to show the toast(s)
// useToast hook is used in this class

import { ToastOptions } from 'vuestic-ui'

// there can be multiple toast messages already in the localStorage
export class ToastService {
  static storeToastMessageJson(toastMessageJson: ToastOptions) {
    const toastMessages = ToastService.loadToastMessagesJson() || []
    toastMessages.push(toastMessageJson)
    localStorage.setItem('toastMessages', JSON.stringify(toastMessages))
  }

  static loadToastMessagesJson(): ToastOptions[] | undefined {
    const toastMessageJson = localStorage.getItem('toastMessages')
    if (toastMessageJson) {
      // clear toast messages from localStorage
      localStorage.removeItem('toastMessages')

      return JSON.parse(toastMessageJson)
    }
    return undefined
  }
}
