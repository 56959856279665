import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import AuthLayout from '../layouts/AuthLayout.vue'
import AppLayout from '../layouts/AppLayout.vue'

import RouteViewComponent from '../layouts/RouterBypass.vue'
import { registerGuard } from './Guard'
import { useAppConfigStore } from '../stores/app-config-store'
import { ToastService } from '../services/toast-service'
import { msalInstance } from '../authConfig'

// const clientRoutes = clientUriSegments.map((segment: string) => ({
//   path: `/${segment}`,
//   name: segment,
//   component: () => import('../pages/clients/ClientHome.vue'), // Load appropriate component
// }))

export const routes: Array<RouteRecordRaw> = [
  // {
  //   path: '/:pathMatch(.*)*',
  //   redirect: { name: 'dashboard' },
  // },

  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        name: 'login',
        path: 'login',
        component: () => import('../pages/auth/Login.vue'),
      },
      {
        name: 'signup',
        path: 'signup',
        component: () => import('../pages/auth/Signup.vue'),
      },
      {
        name: 'recover-password',
        path: 'recover-password',
        component: () => import('../pages/auth/RecoverPassword.vue'),
      },
      {
        name: 'recover-password-email',
        path: 'recover-password-email',
        component: () => import('../pages/auth/CheckTheEmail.vue'),
      },
      {
        path: '',
        redirect: { name: 'login' },
      },
    ],
  },
  {
    name: 'admin',
    path: '/admin',
    component: AppLayout,
    meta: {
      requiresAuth: true,
      requiredRoles: ['ADMIN'],
      clientCodes: ['pw'],
    },
    // redirect: { name: 'dashboard' },
    children: [
      {
        name: 'admin-clients',
        path: 'clients',
        component: () => import('../pages/admin/Clients.vue'),
      },
      {
        name: 'dashboard',
        path: 'dashboard',
        component: () => import('../pages/admin/dashboard/Dashboard.vue'),
      },
      {
        name: 'users',
        path: 'users',
        component: () => import('../pages/users/UsersPage.vue'),
      },
      {
        name: 'projects',
        path: 'projects',
        component: () => import('../pages/projects/ProjectsPage.vue'),
      },
      {
        name: 'payments',
        path: '/payments',
        component: RouteViewComponent,
        children: [
          {
            name: 'payment-methods',
            path: 'payment-methods',
            component: () => import('../pages/payments/PaymentsPage.vue'),
          },
          {
            name: 'billing',
            path: 'billing',
            component: () => import('../pages/billing/BillingPage.vue'),
          },
          {
            name: 'pricing-plans',
            path: 'pricing-plans',
            component: () => import('../pages/pricing-plans/PricingPlans.vue'),
          },
        ],
      },
    ],
  },
  {
    name: '404',
    path: '/404',
    component: () => import('../pages/404.vue'),
  },
  {
    name: 'error',
    path: '/error',
    component: () => import('../pages/Error.vue'),
  },
  {
    path: '/login',
    redirect: { name: 'login' },
  },
  {
    name: 'app',
    path: '/',
    meta: {
      requiresAuth: true,
    },
    component: AppLayout,
    // component: () => import('../pages/test/Test.vue'),
    children: [
      {
        name: 'home',
        path: '',
        redirect: { name: 'test-page' },
      },
      {
        name: 'test-page',
        path: 'test',
        component: () => import('../pages/test/Test.vue'),
      },
      {
        name: 'settings',
        path: '/settings',
        component: () => import('../pages/settings/Settings.vue'),
      },
      {
        name: 'profile',
        path: '/profile',
        component: () => import('../pages/preferences/Preferences.vue'),
      },
      {
        name: 'faq',
        path: '/faq',
        component: () => import('../pages/faq/FaqPage.vue'),
      },
    ],
  },

  // all client routes
  // ...clientRoutes,

  // check clients if no route matches
  {
    // path: '/:catchAll(.*)*',
    path: '/:pathMatch(.*)*',
    name: 'check-clients',
    component: () => import('../layouts/ClientAppLayout.vue'),
    meta: {
      requiresAuth: true,
    },
    // component: RouteViewComponent,
    beforeEnter: async (to, from, next) => {
      // useAppConfigStore
      const appConfigStore = useAppConfigStore()

      // load app config
      const segmentToClientCodeMap = appConfigStore.CLIENT_URL_SEGMENT_CLIENT_CODE_MAP

      // check if the route uri segment is in the clientUriSegments
      const segment = to.path.split('/')[1] as string

      // log
      console.log('router/index.ts uri segment:', segment)

      // check segment against clientUriSegments from the map keys
      const clientCode = segmentToClientCodeMap[segment] // e.g. 'km'

      // log
      console.log('router/index.ts clientCode:', clientCode)

      // if clientCode is not found, redirect to 404
      if (!clientCode) {
        next({ name: '404' })

        return false
      }

      if (clientCode) {
        // then, check if user's idToken's pwRoles (e.g. ["km:ADMIN","ch:DOCTOR"]) contains the required role(s) for the client
        // get idTokenClaims
        const idTokenClaims = msalInstance.getActiveAccount()?.idTokenClaims

        // log
        // console.log('router/index.ts idTokenClaims:', idTokenClaims)

        // check if idTokenClaims is not null
        if (!idTokenClaims) {
          // set toast message in localStorage
          ToastService.storeToastMessageJson({
            message: 'Your token information is not available. Please login again.',
            color: 'danger',
            position: 'bottom-right',
            closeable: true,
          })

          next({ name: 'login' })
          return
        }

        // check if idTokenClaims contains the required role(s)
        const pwRoles = idTokenClaims?.pwRoles as string[]

        // log
        console.log('router/index.ts pwRoles:', pwRoles)

        // check if pwRoles is not null
        if (!pwRoles || pwRoles.length === 0) {
          // set toast message in localStorage
          ToastService.storeToastMessageJson({
            message: 'You have not been assigned with any role(s) yet to access this page.',
            color: 'danger',
            position: 'bottom-right',
            closeable: true,
          })

          next({ name: 'login' })
          return
        }

        // check if pwRoles contains the required role(s)
        const clientCodesFromPwRoles = pwRoles
          .map((role: string) => role.split(':')[0])
          // distinct
          .filter((value, index, self) => self.indexOf(value) === index)

        // log
        console.log('router/index.ts clientCodesFromPwRoles:', clientCodesFromPwRoles)

        // check if clientCodesFromPwRoles contains the clientCode
        if (!clientCodesFromPwRoles.includes(clientCode) && !clientCodesFromPwRoles.includes('pw')) {
          // set toast message in localStorage
          ToastService.storeToastMessageJson({
            message: 'You do not have permission to access this page.',
            color: 'danger',
            position: 'bottom-right',
            closeable: true,
          })

          next({ name: 'login' })
          return
        }

        // TODO: extract
        // const clientCompanyCodes = idTokenClaims?.client as string[]

        next()
        return
      } else {
        // log
        console.log('Client URI segment not found:', segment)

        next({ name: '404' })
        return
      }
    },
    redirect: { name: 'client-home' },
    children: [
      {
        name: 'client-home',
        path: '',
        component: () => import('../pages/clients/ClientHome.vue'),
      },
      {
        name: 'client-admin',
        path: 'admin',
        meta: {
          requiredRoles: ['ADMIN'],
        },
        component: () => import('../pages/clients/ClientLayout.vue'),
        children: [
          {
            name: 'client-admin-users',
            path: 'users',
            component: () => import('../pages/clients/ClientUsers.vue'),
          },
          {
            name: 'client-admin-user-groups',
            path: 'user-groups',
            component: () => import('../pages/clients/ClientUserGroups.vue'),
          },
          {
            name: 'client-admin-settings',
            path: 'settings',
            component: () => import('../pages/clients/ClientSettings.vue'),
          },
        ],
      },
      {
        name: 'client-reports',
        path: 'reports',
        component: () => import('../pages/clients/ClientReports.vue'),
      },
      {
        name: 'client-pricing-plans',
        path: 'pricing-plans',
        meta: {
          requiredRoles: ['ADMIN'],
        },
        component: () => import('../pages/pricing-plans/PricingPlans.vue'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    // For some reason using documentation example doesn't scroll on page navigation.
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' }
    } else {
      window.scrollTo(0, 0)
    }
  },
  routes,
})

registerGuard(router)

export default router
