import { PublicClientApplication } from '@azure/msal-browser'

const scopes = (import.meta.env.VITE_APP_MSAL_ACQUIRE_ACCESS_TOKEN_SCOPES || 'User.Read').split(',')

export const acquireAccessToken = async (instance: PublicClientApplication) => {
  return await instance.acquireTokenSilent({
    // scopes: [
    //   // 'user.read'
    //   // 'https://graph.microsoft.com/User.Read'
    //   'api://pw-lab-portal-funcs.azurewebsites.net/8bff0df2-1764-40dc-86ab-8a9b087b1e49/claim.read',
    // ],
    scopes,
  })
}
