import { defineStore } from 'pinia'

export interface AppConfig {
  CLIENT_URL_SEGMENT_CLIENT_CODE_MAP: Record<string, string>
  CLIENT_CODE_CLIENT_NAME_MAP: Record<string, string>
}

export const useAppConfigStore = defineStore('appConfig', {
  state: () => {
    return {
      CLIENT_URL_SEGMENT_CLIENT_CODE_MAP: {} as Record<string, string>,
      CLIENT_CODE_CLIENT_NAME_MAP: {} as Record<string, string>,
    }
  },

  actions: {
    loadAppConfigs(appConfig: AppConfig) {
      this.CLIENT_URL_SEGMENT_CLIENT_CODE_MAP = appConfig.CLIENT_URL_SEGMENT_CLIENT_CODE_MAP
      this.CLIENT_CODE_CLIENT_NAME_MAP = appConfig.CLIENT_CODE_CLIENT_NAME_MAP
    },
  },
})
