import { createApp } from 'vue'
import i18n from './i18n'
import { createVuestic } from 'vuestic-ui'
import { createGtm } from '@gtm-support/vue-gtm'

import stores from './stores'
import vuesticGlobalConfig from './services/vuestic-ui/global-config'
import App from './App.vue'

import { msalPlugin } from './plugins/msalPlugin'
import { msalInstance } from './authConfig'
import { AuthenticationResult, EventType, PublicClientApplication } from '@azure/msal-browser'
import { CustomNavigationClient } from './router/NavigationClient'
import axiosUtil from './services/axios-helper'
import { AppConfig, useAppConfigStore } from './stores/app-config-store'
import router from './router'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import PDFObjectPlugin from 'pdfobject-vue'

const initializeAppConfigs = async (stores: any, msalInstance: PublicClientApplication) => {
  const response = await (
    await axiosUtil(msalInstance, {
      isAcquireAccessToken: false,
    })
  ).get('/api/app-configs')

  const appConfigs: AppConfig = response.data

  // log the fetched configuration data
  console.log('Fetched app configs:', appConfigs)

  const configStore = useAppConfigStore(stores)

  // Store the fetched configuration data in Pinia store
  configStore.loadAppConfigs(appConfigs)
}

const initializeApp = async () => {
  try {
    const app = createApp(App)

    app.use(stores)

    // The next 2 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
    const navigationClient = new CustomNavigationClient(router)
    msalInstance.setNavigationClient(navigationClient)

    // Account selection logic is app dependent. Adjust as needed for different use cases.
    const accounts = msalInstance.getAllAccounts()
    if (accounts.length > 0) {
      // If there are accounts, set active account to the first account in the list.
      // TODO: Add account chooser code here
      msalInstance.setActiveAccount(accounts[0])
    }
    msalInstance.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult
        const account = payload.account
        msalInstance.setActiveAccount(account)
      } else if (event.eventType === EventType.LOGOUT_SUCCESS) {
        msalInstance.setActiveAccount(null)
      } else if (event.eventType === EventType.LOGIN_FAILURE) {
        alert('Login failure due to ' + event.error)

        console.error('Login failure:', event.error)
      }
    })

    app.use(msalPlugin, msalInstance)
    app.use(i18n)
    app.use(createVuestic({ config: vuesticGlobalConfig }))

    if (import.meta.env.VITE_APP_GTM_ENABLED) {
      app.use(
        createGtm({
          id: import.meta.env.VITE_APP_GTM_KEY,
          debug: false,
          vueRouter: router,
        }),
      )
    }

    try {
      await initializeAppConfigs(stores, msalInstance)
    } catch (error) {
      console.error('Failed to initialize app configs:', error)

      // Show an error page
      router.push({ name: 'error' })
    }

    app.use(router)
    app.use(PDFObjectPlugin)
    app.mount('#app')
  } catch (error) {
    console.error('Failed to initialize the app:', error)

    // alert the user that the app failed to initialize
    alert('Failed to initialize the app. Please try again later.')
  }
}

initializeApp()
