import { defineStore } from 'pinia'

const STORE_NAME = 'main'

const getDefaultSettings = () => ({
  isSidebarMinimized: false,
  isDarkMode: false,
})

const getSettings = () => {
  const settings = localStorage.getItem(STORE_NAME)

  return settings ? JSON.parse(settings) : getDefaultSettings()
}

export const useGlobalStore = defineStore(STORE_NAME, {
  state: () => ({
    settings: getSettings(),
  }),
  actions: {
    updateSettings(partialSettings: Partial<ReturnType<typeof getDefaultSettings>>) {
      this.settings = {
        ...this.settings,
        ...partialSettings,
      }
      localStorage.setItem(STORE_NAME, JSON.stringify(this.settings))
    },
  },
})
