<template>
  <VaSidebar v-model="writableVisible" :width="sidebarWidth" :color="color" minimized-width="0">
    <VaAccordion v-model="value" multiple>
      <VaCollapse v-for="(route, index) in navigationRoutes.routes" :key="index">
        <template #header="{ value: isCollapsed }">
          <!-- add group header name -->
          <div
            v-if="route.headerName"
            :class="isDisplayMenuItem(route) ? 'block' : 'hidden'"
            class="px-4 py-3 text-xs text-secondary"
          >
            {{ t(route.headerName) }}
          </div>

          <!-- if headerName === 'menuHeaders.client-admin', display client selection -->
          <div v-if="route.headerName === 'menuHeaders.client-admin'">
            <!-- show select to switch clients (e.g. "km" for KingMed and "ch" for Chan & Hou) -->
            <VaSelect
              v-model="currentClient"
              :options="availableClients"
              text-by="clientName"
              track-by="clientCode"
              placeholder="Select Client"
              class="px-4 py-0 pb-2"
            />
          </div>

          <VaSidebarItem
            :active="routeHasActiveChild(route)"
            :active-color="activeColor"
            :text-color="textColor(route)"
            :aria-label="`${route.children ? 'Open category ' : 'Visit'} ${t(route.displayName)}`"
            role="button"
            hover-opacity="0.10"
            :class="isDisplayMenuItem(route) ? 'block' : 'hidden'"
            @click="navigateTo(route)"
          >
            <VaSidebarItemContent class="py-3 pr-2 pl-4">
              <VaIcon
                v-if="route.meta.icon"
                aria-hidden="true"
                :name="route.meta.icon"
                size="20px"
                :color="iconColor(route)"
              />
              <VaSidebarItemTitle class="flex justify-between items-center leading-5 font-semibold">
                {{ t(route.displayName) }}
                <VaIcon v-if="route.children" :name="arrowDirection(isCollapsed)" size="20px" />
              </VaSidebarItemTitle>
            </VaSidebarItemContent>
          </VaSidebarItem>
          <!-- if route.addSeparator is true, add a separator -->
          <hr v-if="route.addSeparator" class="mt-5" :class="isDisplayMenuItem(route) ? 'block' : 'hidden'" />
        </template>
        <template #body>
          <div v-for="(childRoute, index2) in route.children" :key="index2">
            <VaSidebarItem
              :active="isActiveChildRoute(childRoute)"
              :active-color="activeColor"
              :class="isDisplayMenuItem(route) ? 'block' : 'hidden'"
              :text-color="textColor(childRoute, route)"
              :aria-label="`Visit ${t(route.displayName)}`"
              hover-opacity="0.10"
              @click="navigateTo(childRoute)"
            >
              <VaSidebarItemContent class="py-3 pr-2 pl-11">
                <VaSidebarItemTitle class="leading-5 font-semibold">
                  {{ t(childRoute.displayName) }}
                </VaSidebarItemTitle>
              </VaSidebarItemContent>
            </VaSidebarItem>
          </div>
        </template>
      </VaCollapse>
    </VaAccordion>

    <!-- display package.json version, position bottom -->
    <div class="absolute bottom-0 left-0 right-0 px-4 py-3 text-center text-xs text-secondary">v{{ appVersion }}</div>
  </VaSidebar>
</template>
<script lang="ts">
import { defineComponent, watch, ref, computed, onMounted } from 'vue'
import { RouteRecordRaw, useRoute, useRouter } from 'vue-router'

import { useI18n } from 'vue-i18n'
import { useColors } from 'vuestic-ui'

import navigationRoutes, { type INavigationRoute } from './NavigationRoutes'
import { useAppConfigStore } from '../../stores/app-config-store'
import { useMsal } from '../../azure/useMsal'
import { Client } from '../../interfaces/client'
import { routes } from '../../router'

export default defineComponent({
  name: 'Sidebar',
  props: {
    visible: { type: Boolean, default: true },
    mobile: { type: Boolean, default: false },
  },
  emits: ['update:visible'],

  setup: (props, { emit }) => {
    const appVersion = import.meta.env.VITE_APP_VERSION
    const { getColor, colorToRgba } = useColors()
    const route = useRoute()
    const router = useRouter()
    const { t } = useI18n()

    const value = ref<boolean[]>([])

    const writableVisible = computed({
      get: () => props.visible,
      set: (v: boolean) => emit('update:visible', v),
    })

    const isActiveChildRoute = (child: INavigationRoute) => route.name === child.name

    const routeHasActiveChild = (section: INavigationRoute) => {
      // directly check with current route
      const currentRoute = router.currentRoute.value

      if (currentRoute.name === section.name) {
        return true
      }

      if (section.children) {
        return section.children.some((child) => currentRoute.name === child.name)
      }

      return false
    }

    const setActiveExpand = () =>
      (value.value = navigationRoutes.routes.map((route: INavigationRoute) => routeHasActiveChild(route)))

    const sidebarWidth = computed(() => (props.mobile ? '100vw' : '280px'))
    const color = computed(() => getColor('background-secondary'))
    const activeColor = computed(() => colorToRgba(getColor('focus'), 0.1))

    const iconColor = (route: INavigationRoute) => (routeHasActiveChild(route) ? 'primary' : 'secondary')
    const textColor = (route: INavigationRoute, parent?: INavigationRoute) => {
      if (route.devOnly || (parent && parent.devOnly)) {
        return 'secondary'
      }
      return routeHasActiveChild(route) ? 'primary' : 'textPrimary'
    }
    const arrowDirection = (state: boolean) => (state ? 'va-arrow-up' : 'va-arrow-down')

    watch(() => route.fullPath, setActiveExpand, { immediate: true })

    // useAppConfigStore
    const appConfigStore = useAppConfigStore()

    const { instance } = useMsal()

    // current client
    const currentClient = ref<Client>()

    // available clients
    const availableClients = ref<Client[]>()

    // pwRoles
    const pwRoles = ref(instance.getActiveAccount()?.idTokenClaims?.pwRoles as string[])

    // initialize current client by uri segment and appConfigStore's CLIENT_URL_SEGMENT_CLIENT_CODE_MAP
    const initializeCurrentClient = () => {
      const uriSegments = window.location.pathname.split('/')
      const clientUriSegment = uriSegments[1]
      // log
      console.log('AppSidebar initializeCurrentClient clientUriSegment', clientUriSegment)

      // pwRoles (e.g. ["km:ADMIN", "ch:ADMIN", "km:DOCTOR", "ch:DOCTOR"])
      // log
      console.log('AppSidebar pwRoles', pwRoles)

      // if pwRoles is not set, return
      if (!pwRoles.value) {
        // log warn
        console.warn('AppSidebar pwRoles is not set')
        return
      }

      // all clients
      const allClients: Partial<Client>[] = appConfigStore.CLIENT_CODE_CLIENT_NAME_MAP
        ? Object.keys(appConfigStore.CLIENT_CODE_CLIENT_NAME_MAP).map((clientCode) => ({
            clientCode,
            clientName: appConfigStore.CLIENT_CODE_CLIENT_NAME_MAP[clientCode],
          }))
        : []

      // first, check if pwRoles contains "pw:ADMIN", if so, set availableClients to allClients
      // otherwise, set availableClients to clients that are in pwRoles
      if (pwRoles.value.includes('pw:ADMIN')) {
        availableClients.value = allClients as Client[]
      } else {
        availableClients.value = pwRoles.value
          ?.map((pwRole: string) => {
            const [clientCode] = pwRole.split(':')
            return clientCode
          })
          // map to client object
          .map((clientCode) => allClients.find((client) => client.clientCode === clientCode))
          // filter out undefined
          .filter((client) => client)
          // filter out duplicates
          .filter(
            (client, index, self) => self.findIndex((c) => c?.clientCode === client?.clientCode) === index,
          ) as Client[]
      }

      // routeClientCode is client code from uri segment
      const routeClientCode = appConfigStore.CLIENT_URL_SEGMENT_CLIENT_CODE_MAP[clientUriSegment]

      // log if routeClientCode is set
      if (routeClientCode) {
        console.log('AppSidebar routeClientCode', routeClientCode)
      }

      // set current client to route client code
      currentClient.value = availableClients.value.find((client) => client.clientCode === routeClientCode)
    }

    // initializeCurrentClient()
    onMounted(() => {
      initializeCurrentClient()
    })

    // watch currentClient, update route to match client code
    watch(currentClient, (after, before) => {
      // appConfigStore.CLIENT_URL_SEGMENT_CLIENT_CODE_MAP is map of client code to uri segment
      const clientUriSegment = Object.keys(appConfigStore.CLIENT_URL_SEGMENT_CLIENT_CODE_MAP).find(
        (key) => appConfigStore.CLIENT_URL_SEGMENT_CLIENT_CODE_MAP[key] === currentClient.value?.clientCode,
      )

      // check if clientUriSegment is already in window location pathname
      const uriSegments = window.location.pathname.split('/')
      const isClientUriSegmentInPathname = uriSegments[1] === clientUriSegment

      if (isClientUriSegmentInPathname) {
        return
      }

      // log
      console.log('AppSidebar clientUriSegment', clientUriSegment)

      if (clientUriSegment) {
        router.push({ name: 'client-home', params: { pathMatch: clientUriSegment } })
      }
    })

    const navigateTo = (iRoute: INavigationRoute) => {
      if (iRoute.children) {
        // if route has children, do not navigate
        return
      }

      // if iRoute has clientUriPath, navigate to clientUriPath
      if (iRoute.clientUriPath) {
        // log
        console.log('AppSidebar navigate to clientUriPath', iRoute.clientUriPath)

        // extract client segment uri from window location pathname
        const clientSegmentUri = window.location.pathname.split('/')[1]

        // navigate to client uri path
        // window.location.href = currentClient.value
        //   ? `/${clientSegmentUri}/${iRoute.clientUriPath}`
        //   : `/${iRoute.clientUriPath}`
        router.push({
          name: iRoute.name,
          params: { pathMatch: clientSegmentUri },
        })
      }
      // else navigate to route name
      else {
        // log
        console.log('AppSidebar navigate to route name', iRoute.name)

        // navigate to route name
        router.push({ name: iRoute.name })
      }
    }

    const isDisplayMenuItem = (iRoute: INavigationRoute) => {
      // log route.name
      // console.log('AppSidebar isDisplayMenuItem route.name', iRoute.name)

      // do not display if clientUriPath is specified and current client is not set
      if (iRoute.clientUriPath && !currentClient.value) {
        return false
      }

      // if route contains clientUriPath, check if current client is set
      // recursively find the route from routes
      const findRoutePath = (routes: RouteRecordRaw[], name: string): RouteRecordRaw[] | undefined => {
        for (const route of routes) {
          if (route.name === name) {
            return [route]
          }
          if (route.children) {
            const foundRoutePath = findRoutePath(route.children, name)
            if (foundRoutePath) {
              return [route, ...foundRoutePath]
            }
          }
        }
      }

      const foundRoutes = findRoutePath(routes, iRoute.name)
      const leafRoute = foundRoutes?.[foundRoutes.length - 1]

      // log leafRoute
      // console.log('AppSidebar isDisplayMenuItem leafRoute', leafRoute)

      // find any clientCodes of parent routes
      const parentClientCodes: string[] =
        foundRoutes?.map((route) => (route.meta?.clientCodes as string[]) ?? []).flat() ?? []

      // log parentClientCodes
      // console.log('AppSidebar isDisplayMenuItem parentClientCodes', parentClientCodes)

      // if found route, check the meta.requiredRoles
      // const requiredRoles = leafRoute?.meta?.requiredRoles as string[]
      const requiredRoles: string[] =
        foundRoutes?.map((route) => (route.meta?.requiredRoles as string[]) ?? []).flat() ?? []

      // if requiredRoles is not set, display the menu item
      if (!requiredRoles || requiredRoles.length === 0) {
        return true
      }

      // if requiredRoles is set, check if idTokenClaims.pwRoles contains requiredRoles
      const pwRoles = instance.getActiveAccount()?.idTokenClaims?.pwRoles as string[]

      // if pwRoles is not set, do not display the menu item
      if (!pwRoles) {
        return false
      }

      const targetClientCodes = [
        'pw',
        ...(parentClientCodes.length > 0 ? parentClientCodes : [currentClient.value?.clientCode]),
      ]
        // dedup
        .filter((clientCode, index, self) => self.indexOf(clientCode) === index)

      // log targetClientCodes
      // console.log('AppSidebar isDisplayMenuItem targetClientCodes', targetClientCodes)

      // filter the pwRoles first. pwRoles is in the format of "clientCode:role"
      const clientPwRoles = pwRoles
        .map((pwRole) => pwRole.split(':'))
        // .filter((clientCodeAndRole) => clientCodeAndRole[0] === currentClient.value?.clientCode)
        .filter((clientCodeAndRole) => targetClientCodes.includes(clientCodeAndRole[0]))
        .map((clientCodeAndRole) => clientCodeAndRole[1])

      // log clientPwRoles
      // console.log('AppSidebar isDisplayMenuItem clientPwRoles', clientPwRoles)

      // if clientPwRoles is not set, do not display the menu item
      if (!clientPwRoles || clientPwRoles.length === 0) {
        return false
      }

      // check if clientPwRoles contains requiredRoles
      if (requiredRoles.some((requiredRole) => clientPwRoles.includes(requiredRole))) {
        return true
      }
      return false
    }

    return {
      writableVisible,
      sidebarWidth,
      value,
      color,
      activeColor,
      navigationRoutes,
      routeHasActiveChild,
      isActiveChildRoute,
      t,
      iconColor,
      textColor,
      arrowDirection,
      currentClient,
      availableClients,
      navigateTo,
      isDisplayMenuItem,
      appVersion,
      pwRoles,
    }
  },
})
</script>
