import { defineStore } from 'pinia'
import { ClientUser } from '../interfaces/client-user'

export const useUserStore = defineStore('user', {
  state: () => {
    return {
      userName: 'N/A',
      email: 'N/A',
      memberSince: 'N/A',
      pfp: 'https://picsum.photos/id/22/200/300',
      is2FAEnabled: false,
      clientUser: null as ClientUser | null,
    }
  },

  actions: {
    toggle2FA() {
      this.is2FAEnabled = !this.is2FAEnabled
    },

    changeAccountInfo(newInfo: Partial<{ userName: string; email: string; memberSince: string; pfp: string }>) {
      // only set the new info if it's not undefined
      Object.entries(newInfo).forEach(([key, value]) => {
        if (value !== undefined) {
          this[key as keyof typeof newInfo] = value
        }
      })
    },

    setClientUser(clientUser: ClientUser) {
      this.clientUser = clientUser
    },
  },
})
