<template>
  <RouterView />
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { ToastService } from './services/toast-service'
import { ToastOptions, useColors, useToast } from 'vuestic-ui'
import { useRoute } from 'vue-router'
import { onMounted, watch } from 'vue'
import { useIsAuthenticated } from './azure/useIsAuthenticated'
import { useMsal } from './azure/useMsal'
import { useRouter } from 'vue-router'
import { useUserStore } from './stores/user-store'
import { useGlobalStore } from './stores/global-store'
import { storeToRefs } from 'pinia'
const { t, locale } = useI18n()
const { init, close, closeAll } = useToast()
const router = useRouter()
const route = useRoute()

const checkAndDisplayToast = () => {
  // load toasts stored in localStorage
  const toasts = ToastService.loadToastMessagesJson()

  // show toasts
  toasts?.forEach((toast: ToastOptions) => {
    init(toast)
  })
}

// Watch for route changes
watch(() => route.path, checkAndDisplayToast)

const isAuthenticated = useIsAuthenticated()

const userStore = useUserStore()

const { accounts } = useMsal()

// watch for isAuthenticated changes
// if isAuthenticated is false, redirect to login page
watch(isAuthenticated, (isAuthenticated) => {
  console.log('isAuthenticated', isAuthenticated)

  if (!isAuthenticated) {
    router.push({ name: 'login' })
  }
})

// updateStoreFromAccounts
const updateStoreFromAccounts = (accounts: any) => {
  console.log('accounts:', [...accounts])

  const activeAccount = accounts[0]
  // set account info into store8888
  userStore.changeAccountInfo({
    userName: activeAccount?.name ?? 'N/A',
    email: activeAccount?.username ?? 'N/A',
  })
}

// watch for accounts changes
watch(accounts, (accounts) => {
  console.log('accounts changed:', [...accounts])

  // update store from accounts
  updateStoreFromAccounts(accounts)
})

onMounted(() => {
  const savedLocale = window.localStorage.getItem('locale')
  if (savedLocale) {
    locale.value = savedLocale
  }

  const { settings } = storeToRefs(useGlobalStore())
  const { applyPreset, currentPresetName } = useColors()

  applyPreset(settings.value.isDarkMode ? 'dark' : 'light')

  // if isDarkMode, add 'dark' class to html element
  if (settings.value.isDarkMode) {
    document.documentElement.classList.add('dark')
  } else {
    document.documentElement.classList.remove('dark')
  }
  document.documentElement.classList.add('dark')

  // check and display toasts
  checkAndDisplayToast()

  // update store from accounts
  updateStoreFromAccounts(accounts.value)
})
</script>
<style lang="scss">
@import 'scss/main.scss';

#app {
  font-family: 'Inter', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  min-width: 20rem;
}
</style>
