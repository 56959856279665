import axios from 'axios'
import { PublicClientApplication } from '@azure/msal-browser'
import { acquireAccessToken } from '../azure/acquire-access-token'

// use VITE_PW_WEBAPI_HTTPS, VITE_PW_WEBAPI_HOST, VITE_PW_WEBAPI_PORT to compose baseUrl with string interpolation
axios.defaults.baseURL = `http${import.meta.env.VITE_PW_WEBAPI_HTTPS === 'true' ? 's' : ''}://${
  import.meta.env.VITE_PW_WEBAPI_HOST
}:${import.meta.env.VITE_PW_WEBAPI_PORT}`

// axiosUtil
export const axiosUtil = async (
  msalInstance: PublicClientApplication,
  {
    isAcquireAccessToken = true,
  }: {
    isAcquireAccessToken?: boolean
  } = {
    isAcquireAccessToken: true,
  },
) => {
  // Initialize app configs here

  await msalInstance.initialize()

  // get access token
  if (isAcquireAccessToken) {
    const accessToken = await acquireAccessToken(msalInstance)

    // set access token to axios header
    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken.accessToken}`
  }

  // set azure api management subscription key to axios header
  axios.defaults.headers.common['Ocp-Apim-Subscription-Key'] = import.meta.env.VITE_PW_WEBAPI_SUB_KEY

  return axios
}

export default axiosUtil
