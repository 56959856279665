<template>
  <VaLayout class="h-screen">
    <template #content>
      <main class="h-full w-full flex flex-col gap-10 items-center justify-center">
        <!-- floated white card -->
        <div class="flex flex-col items-center gap-8 p-12 rounded-lg shadow-lg w-[90%] md:w-2/3 max-w-[520px]">
          <div class="flex flex-row gap-5 items-center">
            <RouterLink to="/" aria-label="Visit home page">
              <!-- img with src path: public/pw/pathways logo.png  -->
              <img src="/pw/pathways-logo.png" alt="Pathways Logo" class="logo w-16 h-16" />
            </RouterLink>
            <span class="flex flex-row gap-3 items-center">
              <!-- title: Pathways LIS Management Portal  -->
              <h1 class="flex font-normal text-2xl">Pathways Lab Portal</h1>
            </span>
          </div>
          <div class="flex flex-col items-start">
            <RouterView />
          </div>
        </div>
      </main>
    </template>
  </VaLayout>
  <!--
  <VaLayout v-else class=" h-screen bg-[var(--va-background-secondary)]">
    <template #content>
      <div class="p-4">
        <main class="h-full flex flex-row items-center justify-start mx-auto max-w-[420px]">
          <div class="flex flex-col items-start">
            <RouterLink class="py-4" to="/" aria-label="Visit homepage">
              <VuesticLogo class="mb-2" start="#0E41C9" />
            </RouterLink>
            <RouterView />
          </div>
        </main>
      </div>
    </template>
  </VaLayout> -->
</template>

<script lang="ts" setup>
import { useBreakpoint } from 'vuestic-ui'
import VuesticLogo from '../components/VuesticLogo.vue'

const breakpoint = useBreakpoint()
</script>
